import { useSession } from 'src/sdk/session'
import { gql } from '@faststore/graphql-utils'
import { graphql } from 'gatsby'
import {
  BreadcrumbJsonLd,
  GatsbySeo,
  ProductJsonLd,
} from 'gatsby-plugin-next-seo'
import { mark } from 'src/sdk/tests/mark'
import type { PageProps } from 'gatsby'
import type {
  ProductPageQueryQuery,
  ServerProductPageQueryQuery,
  ProductPageQueryQueryVariables,
} from '@generated/graphql'
import { ProductPageProvider } from 'src/hooks/productPage'
import ProductDetails from 'src/components/sections/ProductDetails'
// import ProductDescription from 'src/components/common/ProductDescription'
import RenderCMS from 'src/components/RenderCMS'
import { BASE_VTEX_URL, fetchAPI } from 'src/services/api'
import type { Promotion, PromotionNames } from 'src/api/promotions'
import { getPromotionsData, customerLogics } from 'src/api/promotions'
import type { Section } from 'src/cms/client'
import { getAllCMSPageDataByContentType } from 'src/cms/client'

export type Props = PageProps<
  ProductPageQueryQuery,
  ProductPageQueryQueryVariables,
  unknown,
  ServerProductPageQueryQuery & {
    cms: { sections: Section[] }
    promotions: Promotion[]
    error?: any
  }
> & { slug: string }

function Page(props: Props) {
  const { currency } = useSession()
  const {
    data: { site },
    serverData,
    location: { host, pathname, search },
  } = props

  if (!serverData || !serverData.product) {
    return null
  }

  const {
    product,
    cms: { sections },
    promotions,
  } = serverData

  const {
    isVariantOf: { slug },
  } = product

  const title = product?.seo.title ?? site?.siteMetadata?.title ?? ''
  const description =
    product?.seo.description ?? site?.siteMetadata?.description ?? ''

  const canonical =
    host !== undefined
      ? `https://${host}${slug}`
      : slug
      ? `${site?.siteMetadata?.siteUrl}${slug}`
      : `${search.match('(page=0)') ? pathname.replace(/\/$/, '') : pathname}${
          search.match('(page=0)')
            ? ''
            : search.replace(/.+(?=page=[0-9]*)/gm, '')
        }`

  const breadcrumbList = product.breadcrumbList.itemListElement.map(
    (breadcrumb) => {
      if (breadcrumb.item.substring(breadcrumb.item.length - 1) === '/') {
        breadcrumb.item = breadcrumb.item.slice(0, -1)
      }

      return breadcrumb
    }
  )

  const parsedOffers = {
    ...serverData?.product?.offers,
    offers: serverData?.product?.offers?.offers?.map((offer) => ({
      ...offer,
      url: canonical,
      priceCurrency: offer.priceCurrency || currency.code,
    })),
    offerCount: serverData.product.stock,
  }

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={
          site?.siteMetadata?.titleTemplate &&
          title.endsWith(site.siteMetadata.titleTemplate.replace('%s', ''))
            ? '%s'
            : site?.siteMetadata?.titleTemplate ?? ''
        }
        description={description}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'og:product',
          url: `${site?.siteMetadata?.siteUrl}${slug}`,
          title,
          description,
          images: product.image.map((img) => ({
            url: img.url,
            alt: img.alternateName,
          })),
        }}
        metaTags={[
          {
            property: 'product:price:amount',
            content: product.offers.lowPrice?.toString() ?? undefined,
          },
          {
            property: 'product:price:currency',
            content: product.offers.priceCurrency || currency.code,
          },
        ]}
      />
      <BreadcrumbJsonLd itemListElements={breadcrumbList ?? []} />
      <ProductJsonLd
        name={product.name}
        description={product.description ?? ''}
        brand={product.brand.name}
        sku={product.sku}
        gtin={product.gtin}
        images={product.image.map((img) => img.url)} // Somehow, Google does not understand this valid Schema.org schema, so we need to do conversions
        offersType="AggregateOffer"
        offers={{
          ...parsedOffers,
          price: product.offers.offers[0].price.toString(),
          priceCurrency: product.offers.priceCurrency || currency.code,
          url: canonical,
          seller: {
            type: 'Organization',
            name: 'Miess',
          },
        }}
        aggregateRating={
          product.isVariantOf.ratings.count
            ? {
                bestRating: 5,
                worstRating: 0,
                reviewCount: product.isVariantOf.ratings.count,
                ratingValue: product.isVariantOf.ratings.average,
                ratingCount: product.isVariantOf.ratings.count,
              }
            : undefined
        }
      />

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}

      <ProductPageProvider
        skuId={product.id}
        staleProduct={product}
        promotions={promotions}
      >
        <ProductDetails />
        {/* ! TODO <BuyTogether /> */}

        <RenderCMS
          sections={
            // skuData?.sections ?? // SKU
            // pdpData?.sections ?? // ID
            // categoryData?.sections ?? // BRAND/CATEGORY
            // defaultData?.sections ?? // DEFAULT
            sections
          }
          extraProps={{}}
        />
      </ProductPageProvider>
    </>
  )
}

/**
 * This query is run during SSG
 * */
export const querySSG = graphql`
  query ProductPageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
  }
`

/**
 * This query is run during SSR
 * */
export const querySSR = gql`
  query ServerProductPageQuery($id: String!) {
    product(locator: [{ key: "id", value: $id }]) {
      id: productID
      slug
      fullTitle

      seo {
        title
        canonical
        description
      }

      brand {
        name
      }

      isVariantOf {
        ratings {
          average
          count
        }
      }

      slug
      sku
      gtin
      name

      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }

      image {
        url
        alternateName
      }

      offers {
        lowPrice
        highPrice
        priceCurrency
        offers {
          availability
          price
          priceValidUntil
          priceCurrency
          itemCondition
          seller {
            identifier
          }
        }
      }

      ...ProductDetailsFragment_product
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  const ONE_YEAR_CACHE = `s-maxage=31536000, stale-while-revalidate`

  const originalUrl = `/${slug}/p`

  try {
    const oldSlugResponseData = await fetchAPI<any[]>(
      `${BASE_VTEX_URL}/api/catalog_system/pub/products/search${encodeURI(
        originalUrl
      )}`
    )

    if (oldSlugResponseData?.length <= 0) {
      const params = new URLSearchParams({
        from: encodeURIComponent(`/${slug}/p`),
      })

      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': ONE_YEAR_CACHE,
          location: `/404/?${params.toString()}`,
        },
      }
    }

    const firstSku = oldSlugResponseData?.[0]?.items?.[0]?.itemId
    const firstSkuWithStock = oldSlugResponseData?.[0]?.items.find(
      (item: any) => item.sellers?.[0]?.commertialOffer.IsAvailable
    )?.itemId

    if (!firstSkuWithStock && !firstSku) {
      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
          location: `/404/?from=${encodeURIComponent(originalUrl)}`,
        },
      }
    }

    let error: any

    const { execute } = await import('src/server')
    const [response, cmsData, promotions] = await Promise.all([
      execute<any, ServerProductPageQueryQuery>({
        operationName: querySSR,
        variables: { id: firstSkuWithStock ?? firstSku },
      }),
      getAllCMSPageDataByContentType('pdp'),
      new Promise((resolve) => {
        getPromotionsData()
          .then(resolve)
          .catch((err) => {
            error = err
            resolve(
              Object.keys(customerLogics).map((key) => ({
                name: key as PromotionNames,
                value: 0,
              }))
            )
          })
      }),
    ])

    const { data } = response

    if (data === null) {
      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
          location: `/404/?from=${encodeURIComponent(originalUrl)}`,
        },
      }
    }

    const { product } = data as ServerProductPageQueryQuery

    const getSections = () => {
      if (!cmsData?.[0]) {
        return []
      }

      for (const page of cmsData) {
        if (page.config?.config?.skuId === product.sku) {
          return page.sections
        }

        if (
          page.config?.config?.productId === product.isVariantOf.productGroupID
        ) {
          return page.sections
        }

        const categoryIdsSet = product.isVariantOf.categoryTrees?.reduce(
          (acc, cat) => {
            cat?.categoryIds?.forEach((catId) => catId && acc.add(catId))

            return acc
          },
          new Set<string>()
        )

        const categoryIds = Array.from(categoryIdsSet ?? [])

        const parsedCategories = [product.brand.id, ...categoryIds]

        if (parsedCategories.includes(page.config?.config?.categoryId)) {
          return page.sections
        }
      }

      return (
        cmsData?.find(
          (page) =>
            !page.config?.config?.skuId &&
            !page.config?.config?.productId &&
            !page.config?.config?.categoryId
        )?.sections ?? []
      )
    }

    return {
      status: 200,
      props:
        { ...data, cms: { sections: getSections() }, promotions, error } ?? {},
      headers: {
        'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
      },
    }
  } catch (err) {
    console.error(err)

    return {
      status: 500,
      props: null,
      headers: {
        'cache-control': 'public, max-age=0, must-revalidate',
      },
    }
  }
}

Page.displayName = 'Page'

export default mark(Page)
